.Observe_plan_execute_main_container{ 
    margin-top: 20px;
    .contents{
        width: 95%;
        margin: auto;
        height: 545px;
        
        
        background: radial-gradient(108.88% 105.37% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
        /* Glass_Effect */
        
        box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(127px);
        /* Note: backdrop-filter has minimal browser support */
        
        border-radius: 20px;

       
        
        .text_img{

            width: 80%;
            margin: auto;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 30px;
            
            p{
                font-size: 72px;
                width: 361px;
                height: 270px;
                font-weight: 800;
                line-height: 115.9%;
                color: #FFFFFF;
                margin-top: 100px;
            }
        }
    }

    .color_container{
        width: 150px;
        height: 388px;
        background: #2EA3DC;
        filter: blur(200px);
        position: absolute;
        margin-left: 90%;
        margin-top: 10px;
    }
}