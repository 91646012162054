.about_us_main_container{
    margin-top: 50px;
    h1{
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #FFFFFF;
        text-align: center;
    }

    p{
        width: 95%;
        margin: auto;
        height: 162px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: justify;
        color: white;
    }
}