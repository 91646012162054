.navbar_main_container{
    height: 50px;
    .navbar_contents{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .image_div{
            // padding-left: 60px;
            width: 388px;
            height: 388px;
            background: #2EA3DC;
            filter: blur(500px);
            position: absolute;
           
        }
        img{
            margin-top: 10px;
            width: 221px;
            height: 40px;
            margin-left: 45px;
        }
       
        .links_div{
            width: 450px;
            padding-right: 50px;
            ul{
                height: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                list-style-type: none;
                margin-top: 15px;

                li{
                    a{
                        color: white;
                        text-decoration: none;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}