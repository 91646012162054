.contact_us_main_container{
    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: white;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
       color: white;
      }
    margin-top: 50px;
    h1{
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 50px;
    }

    .input_div{
        width: 50%;
        margin: auto;
        background: radial-gradient(141.32% 179.48% at -11% -20.06%, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0) 100%);
        box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(127px);
        border-radius: 20px;
        padding: 55px 0px;

        input{
            width: 70%;
            margin: auto;
            display: block;
            height: 67px;
            background: #1A1E3B;
            border-radius: 12px;
            margin-bottom: 20px;
            border: none;
            padding-left: 20px;
            color: white;
        }

        textarea{
            width: 70%;
            height: 257px;
            background: #1A1E3B;
            border-radius: 12px;
            padding-left: 20px;
            margin: auto;
            display: block;
            color: white;
            border: none;
            margin-bottom: 20px;
            padding-top: 10px;
        }

        button{
            width: 70%;
            height: 66px;
            background: #2EA3DC;
            border-radius: 12px;
            margin: auto;
            display: block;
            color: black;
            border: none;
            font-size: 1.2rem;
            font-weight: bold;
       }
    }
    .color_container{
        width: 300px;
        height: 500px;
        background: #2EA3DC;
        filter: blur(200px);
        margin-top: -480px;
    }
}