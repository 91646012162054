.footer_main_container{
    background-color: #1A1E3C;
    height: 50px;
    width: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p{
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        font-style: normal;
        margin-left: 42%;
        
    }

    .socila_meida_icons{
        width: 200px;
        ul{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            list-style-type: none;
            padding-right: 20px;
            li{
                color: white;
            }
        }
    }
}